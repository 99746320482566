/* Sidebar Styles */
.sidebar {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100%;
  background-color: #133b19;
  color: white;
  transition: all 0.3s ease;
  z-index: 1000;
}

.leftMenus{
  width: 300px;
  height: 100%;
  background-color: #133b19;
  color: white;
  transition: all 0.3s ease;
  z-index: 1000;
}
.sidebar_open {
  left: 0;
  z-index: 99999;
}

.main_content {
  margin-left: 250px; /* Create space equal to the sidebar width */
}

.sidebar_header {
  display: flex;
  justify-content: center;
  padding: 1rem;
  background-color: #133b19;
}

.back_arrow {
  font-size: 2rem;
  cursor: pointer;
  margin-right: 1rem;
}

.sidebar_menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar_item {
  display: flex;
  border-bottom: 1px solid #444;
  align-items: center;
  color: white;
  width: 100%;
  line-height: 2rem;
  padding: 1rem;
}

.sidebar_item:hover a {
  color: #38904a;
}

.sidebar_item:hover span {
  color: #38904a;
}

.sidebar_item:hover a svg {
  fill: #38904a;
}

.sidebar_item:hover svg {
  fill: #38904a;
}

.sidebar_item a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  /* width: 100%;
  line-height: 2rem;
  padding: 1rem; */
}

.sidebar_item a span,
.sidebar_item span {
  padding-left: 0.5rem;
}

.sidebar_menu li:hover {
  background-color: #ffffff;
  color: #444;
}

.logo {
  width: 150px;
  height: 100px;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.submenu {
  list-style: none;
  transition: all 0.8s ease-out;
}

.sidebar_subitem {
  /* padding: 10px 0; */
  font-size: 14px;
  border-bottom: 1px solid #444;
  padding-left: 1.5rem;
}

.sidebar_menu li:hover a svg {
  fill: #38904a;
}

.sidebar_subitem a {
  text-decoration: none;
  color: inherit;
  width: 100%;
  line-height: 2rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.sidebar_subitem a:hover {
  color: #38904a;
}

.arrow {
  margin-left: auto;
  font-size: 12px;
}


